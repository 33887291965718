
import './App.css';
import Routing from './Routing';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';


function App() {
  return (
   <>
        <Routing/>
   </>
  );
}

export default App;
